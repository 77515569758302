import { EAuthCode } from '~/enums/EAuthCode';
import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.projectContract,
    component: () => import('../../pages/project/contract'),
    meta: {
      title: '合同列表'
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTCONTRACT
    }
  },
  {
    path: routeNames.projectOrders,
    component: () => import('../../pages/project/orders'),
    meta: {
      title: '正式实验下单列表'
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTORDERS
    }
  },
  {
    path: routeNames.projectOrderDetail,
    component: () => import('../../pages/project/orderDetail'),
    meta: {
      title: '订单详情', hideInMenu: true.valueOf
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTORDERDETAIL
    }
  },
  {
    path: routeNames.projectMissions,
    component: () => import('../../pages/project/missions'),
    meta: {
      title: '任务单列表'
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTMISSIONS
    }
  },
  {
    path: routeNames.projectMissionDetail,
    component: () => import('../../pages/project/missionDetail'),
    meta: {
      title: '任务单详情', hideInMenu: true.valueOf
      , code: EAuthCode.PROJECTMANAGEMENT_PROJECTMISSIONS_DETAIL
    }
  }
] as TRoutes[]