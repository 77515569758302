export enum EAuthCode {
  /** 系统管理 */
  DEPARTMENTMANAGEMENT = 'departmentManagement',
  /** 系统管理-系统设置 */
  DEPARTMENTMANAGEMENT_SYSTEMSET = 'departmentManagement-systemSet',
  /** 系统管理-系统设置-自动完工设置 */
  DEPARTMENTMANAGEMENT_SYSTEMSET_COMPLETE = 'departmentManagement-systemSet-complete',
  /** 系统管理-系统设置-客服电话设置 */
  DEPARTMENTMANAGEMENT_SYSTEMSET_CLOSESHOP = 'departmentManagement-systemSet-closeShop',

  /** 系统管理-操作日志 */
  DEPARTMENTMANAGEMENT_OPERATIONLOG = 'departmentManagement-operationLog',

  /** 系统管理-员工管理 */
  DEPARTMENTMANAGEMENT_STAFF = 'departmentManagement-staff',
  /** 系统管理-员工管理-预览 */
  DEPARTMENTMANAGEMENT_STAFF_QUERY = 'departmentManagement-staff-query',
  /** 系统管理-员工管理-新增 */
  DEPARTMENTMANAGEMENT_STAFF_ADD = 'departmentManagement-staff-add',
  /** 系统管理-员工管理-编辑 */
  DEPARTMENTMANAGEMENT_STAFF_UPDATE = 'departmentManagement-staff-update',
  /** 系统管理-员工管理-删除 */
  DEPARTMENTMANAGEMENT_STAFF_DELETE = 'departmentManagement-staff-delete',
  /** 系统管理-员工管理-重置密码 */
  DEPARTMENTMANAGEMENT_STAFF_RESETPWD = 'departmentManagement-staff-resetPWD',

  /** 系统管理-部门管理 */
  DEPARTMENTMANAGEMENT_DEPT = 'permissions-dept',
  /** 系统管理-部门管理-预览 */
  DEPARTMENTMANAGEMENT_DEPT_QUERY = 'departmentManagement-dept-query',
  /** 系统管理-部门管理-新增 */
  DEPARTMENTMANAGEMENT_DEPT_ADD = 'departmentManagement-dept-add',
  /** 系统管理-部门管理-编辑 */
  DEPARTMENTMANAGEMENT_DEPT_UPDATE = 'departmentManagement-dept-update',
  /** 系统管理-部门管理-删除 */
  DEPARTMENTMANAGEMENT_DEPT_DELETE = 'departmentManagement-dept-delete',

  /** 系统管理-角色管理 */
  DEPARTMENTMANAGEMENT_ROLE = 'departmentManagement-role',
  /** 系统管理-角色管理-预览 */
  DEPARTMENTMANAGEMENT_ROLE_QUERY = 'departmentManagement-role-query',
  /** 系统管理-角色管理-新增 */
  DEPARTMENTMANAGEMENT_ROLE_ADD = 'departmentManagement-role-add',
  /** 系统管理-角色管理-编辑 */
  DEPARTMENTMANAGEMENT_ROLE_UPDATE = 'departmentManagement-role-update',
  /** 系统管理-角色管理-删除 */
  DEPARTMENTMANAGEMENT_ROLE_DELETE = 'departmentManagement-role-delete',

  /** 用户管理 */
  USERMANAGEMENT = 'userManagement',
  /** 用户管理-用户列表 */
  USERMANAGEMENT_USERLIST = 'userManagement-userList',
  /** 用户管理-用户列表-是否展示 */
  USERMANAGEMENT_USERLIST_ISSHOW = 'userManagement-userList-isShow',

  /** 项目管理 */
  PROJECTMANAGEMENT = 'projectManagement',
  /** 项目管理-合同列表 */
  PROJECTMANAGEMENT_PROJECTCONTRACT = 'projectManagement-projectContract',
  /** 项目管理-合同列表-下载 */
  PROJECTMANAGEMENT_PROJECTCONTRACT_DOWNLOAD = 'projectManagement-projectContract-download',

  /** 项目管理-正式实验下单列表 */
  PROJECTMANAGEMENT_PROJECTORDERS = 'projectManagement-projectOrders',
  /** 项目管理-正式实验下单列表-详情 */
  PROJECTMANAGEMENT_PROJECTORDERS_DETAIL = 'projectManagement-projectOrders-detail',

  /** 项目管理-订单详情 */
  PROJECTMANAGEMENT_PROJECTORDERDETAIL = 'projectManagement-projectOrderDetail',

  /** 项目管理-任务单列表 */
  PROJECTMANAGEMENT_PROJECTMISSIONS = 'projectManagement-projectMissions',
  /** 项目管理-任务单列表-详情 */
  PROJECTMANAGEMENT_PROJECTMISSIONS_DETAIL = 'projectManagement-projectMissions-detail',

}