import { IRequestConfig } from "~/modules/@wmeimob/request/src/types/fetch-type";
import {
  AdminApiSysdeptDeleteDeleteParams,
  AdminApiSysdeptQueryGetParams,
  AdminApiSysRoleDeleteDeleteParams,
  AdminApiSysRoleQueryAllGetParams,
  AdminApiSysRoleQueryGetParams,
  AdminApiSysStoreUserGroupDeleteDeleteParams,
  AdminApiSysStoreUserGroupDetailGetParams,
  AdminApiSysStoreUserGroupQueryGetParams,
  AdminApiSysUserDeleteDeleteParams,
  AdminApiSysUserQueryGetParams,
  AdminApiSysUserResetDefaultDeleteParams,
  AdminV1ContractQueryGetParams,
  AdminV1OrderEmptyOrderGetParams,
  AdminV1OrderQueryGetParams,
  AdminV1TaskNodeConvertListGetParams,
  AdminV1TaskOrderPageGetParams,
  AdminV1WeixinuserGetParams,
  CompleteOrderDto,
  ContractDto,
  ContractUpdateVo,
  EmptyOrderDto,
  JsonResultContractDto,
  JsonResultListSettingDTO,
  JsonResultListSysDeptHaveUserVo,
  JsonResultListSysDeptVo,
  JsonResultListSysMenuVo,
  JsonResultListSysRoleDto,
  JsonResultListTaskNodeConvertDto,
  JsonResultLong,
  JsonResultObject,
  JsonResultOrderDto,
  JsonResultPageInfoContract,
  JsonResultPageInfoEmptyOrderDto,
  JsonResultPageInfoOrder,
  JsonResultPageInfoSysDeptVo,
  JsonResultPageInfoSysRoleDto,
  JsonResultPageInfoSysStoreUserGroupVo,
  JsonResultPageInfoSysUser,
  JsonResultPageInfoSysUserVo,
  JsonResultPageInfoTaskOrderDto,
  JsonResultSettingDTO,
  JsonResultString,
  JsonResultSysAuthVo,
  JsonResultSysStoreUserGroupVo,
  JsonResultTaskOrderDto,
  JsonResultVoid,
  LoginDto,
  OrderDto,
  OrderUpdateVo,
  RegisterDto,
  ResetPwdParam,
  SettingSaveDTO,
  SysDeptAddParam,
  SysRoleAddParam,
  SysStoreUserGroupAddParam,
  SysUserAddParam
} from "./data-contracts";
import requestInstance from "./instance";

type RequestConfig = Omit<IRequestConfig, "url" | "method">;

export const API = {
  /**
   * No description
   * @name POST /admin/api/sysRole/add
   * @summary 新增角色(返回ID)
   * @tags 系统-角色表
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysRole/add_POST": (entity: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/api/sysRole/allMenu
   * @summary 所有菜单模块
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysMenuVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysRole/allMenu_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/allMenu`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysMenuVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysRole/delete
   * @summary 角色删除
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/api/sysRole/delete_DELETE": (query: AdminApiSysRoleDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysRole/query
   * @summary 角色表分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultPageInfoSysRoleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysRole/query_GET": (query: AdminApiSysRoleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysRoleDto>,
  /**
   * No description
   * @name GET /admin/api/sysRole/queryAll
   * @summary 角色表不分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysRoleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysRole/queryAll_GET": (query: AdminApiSysRoleQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/queryAll`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSysRoleDto>,
  /**
   * No description
   * @name PUT /admin/api/sysRole/update
   * @summary 修改角色
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysRole/update_PUT": (entity: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysStoreUserGroup/add
   * @summary 资源分组新增(返回ID)
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysStoreUserGroup/add_POST": (entity: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysStoreUserGroup/delete
   * @summary 资源分组删除
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/api/sysStoreUserGroup/delete_DELETE": (
    query: AdminApiSysStoreUserGroupDeleteDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/detail
   * @summary 资源分组详情
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultSysStoreUserGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysStoreUserGroup/detail_GET": (
    query: AdminApiSysStoreUserGroupDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/detail`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysStoreUserGroupVo>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/query
   * @summary 资源分组分页查询
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultPageInfoSysStoreUserGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysStoreUserGroup/query_GET": (
    query: AdminApiSysStoreUserGroupQueryGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysStoreUserGroupVo>,
  /**
   * No description
   * @name PUT /admin/api/sysStoreUserGroup/update
   * @summary 资源分组修改
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysStoreUserGroup/update_PUT": (entity: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysUser/add
   * @summary 员工新增(返回ID)
   * @tags 系统-员工管理
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysUser/add_POST": (entity: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/delete
   * @summary 员工删除
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/api/sysUser/delete_DELETE": (query: AdminApiSysUserDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysUser/query
   * @summary 员工表分页查询
   * @tags 系统-员工管理
   * @response `200` `JsonResultPageInfoSysUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysUser/query_GET": (query: AdminApiSysUserQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysUserVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/resetDefault
   * @summary 重置密码
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/api/sysUser/resetDefault_DELETE": (
    query: AdminApiSysUserResetDefaultDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/sysUser/resetDefault`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/sysUser/update
   * @summary 员工修改
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysUser/update_PUT": (entity: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysdept/add
   * @summary 部门新增(返回ID)
   * @tags 部门表
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysdept/add_POST": (entity: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/add`,
      method: "POST",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysdept/delete
   * @summary 部门删除
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/api/sysdept/delete_DELETE": (query: AdminApiSysdeptDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/delete`,
      method: "DELETE",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysdept/query
   * @summary 部门表分页查询
   * @tags 部门表
   * @response `200` `JsonResultPageInfoSysDeptVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysdept/query_GET": (query: AdminApiSysdeptQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAll
   * @summary 部门表不分页查询
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysdept/queryAll_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/queryAll`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAllHaveUser
   * @summary 部门表不分页查询（包含员工信息）
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptHaveUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysdept/queryAllHaveUser_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/queryAllHaveUser`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptHaveUserVo>,
  /**
   * No description
   * @name PUT /admin/api/sysdept/update
   * @summary 部门修改
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/api/sysdept/update_PUT": (entity: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/update`,
      method: "PUT",
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/auth/forgot
   * @summary 忘记密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/auth/forgot_POST": (loginDto: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/forgot`,
      method: "POST",
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 默认账号:19999999999 密码ocj123456 密码需要aes加密，获取token后放在header中{Authorization:Bearer 你的token}
   * @name POST /admin/auth/login
   * @summary 登录
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/auth/login_POST": (loginDto: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/login`,
      method: "POST",
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/auth/query
   * @summary 获取当前用户权限
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultSysAuthVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/auth/query_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/query`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultSysAuthVo>,
  /**
   * No description
   * @name POST /admin/auth/register
   * @summary 注册（返回token）
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/auth/register_POST": (loginDto: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/register`,
      method: "POST",
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/auth/resetPwd
   * @summary 修改密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/auth/resetPwd_PUT": (resetPwdParam: ResetPwdParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/resetPwd`,
      method: "PUT",
      data: resetPwdParam,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/v1/contract
   * @summary 对接-增加合同
   * @tags 合同
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/contract_POST": (contract: ContractDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract`,
      method: "POST",
      data: contract,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/v1/contract/code/{code}
   * @summary 对接-撤回合同
   * @tags 合同
   * @response `200` `JsonResultObject` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/v1/contract/code/{code}_DELETE": (code: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract/code/${code}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/v1/contract/contractUrl/{id}
   * @summary 获取pdf文件链接
   * @tags 合同
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/contract/contractUrl/{id}_GET": (id: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract/contractUrl/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/v1/contract/query
   * @summary 合同列表分页查询
   * @tags 合同
   * @response `200` `JsonResultPageInfoContract` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/contract/query_GET": (query: AdminV1ContractQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoContract>,
  /**
   * No description
   * @name GET /admin/v1/contract/{id}
   * @summary 根据id查询
   * @tags 合同
   * @response `200` `JsonResultContractDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/contract/{id}_GET": (id: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultContractDto>,
  /**
   * No description
   * @name PUT /admin/v1/contract/{id}
   * @summary 更新合同
   * @tags 合同
   * @response `200` `JsonResultObject` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/contract/{id}_PUT": (id: string, contractUpdate: ContractUpdateVo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract/${id}`,
      method: "PUT",
      data: contractUpdate,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/v1/contract/{id}/download
   * @summary 合同下载记录
   * @tags 合同
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/contract/{id}/download_GET": (id: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/contract/${id}/download`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name POST /admin/v1/order
   * @summary 对接-增加订单
   * @tags 正式实验单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order_POST": (order: OrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order`,
      method: "POST",
      data: order,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/v1/order/code/emptyOrder
   * @summary 对接-新增结算单
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/code/emptyOrder_POST": (emptyOrder: EmptyOrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/code/emptyOrder`,
      method: "POST",
      data: emptyOrder,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name DELETE /admin/v1/order/code/{code}
   * @summary 对接-撤回订单 任务单号
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/v1/order/code/{code}_DELETE": (code: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/code/${code}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name POST /admin/v1/order/code/{code}/completeOrder
   * @summary 对接-新增完工单
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/code/{code}/completeOrder_POST": (
    code: string,
    completeOrderDto: CompleteOrderDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/v1/order/code/${code}/completeOrder`,
      method: "POST",
      data: completeOrderDto,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name DELETE /admin/v1/order/completeOrder/code/{code}
   * @summary 对接-撤回完工单
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/v1/order/completeOrder/code/{code}_DELETE": (code: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/completeOrder/code/${code}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/v1/order/emptyOrder
   * @summary 分页条件查询所有结算单
   * @tags 正式实验单
   * @response `200` `JsonResultPageInfoEmptyOrderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/emptyOrder_GET": (query: AdminV1OrderEmptyOrderGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/emptyOrder`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoEmptyOrderDto>,
  /**
   * No description
   * @name DELETE /admin/v1/order/emptyOrder/code/{code}
   * @summary 对接-撤回结算单
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  "/admin/v1/order/emptyOrder/code/{code}_DELETE": (code: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/emptyOrder/code/${code}`,
      method: "DELETE",
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/v1/order/query
   * @summary 分页条件查询所有订单
   * @tags 正式实验单
   * @response `200` `JsonResultPageInfoOrder` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/query_GET": (query: AdminV1OrderQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/query`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrder>,
  /**
   * No description
   * @name GET /admin/v1/order/{id}
   * @summary 根据id查询
   * @tags 正式实验单
   * @response `200` `JsonResultOrderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/{id}_GET": (id: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultOrderDto>,
  /**
   * No description
   * @name PUT /admin/v1/order/{id}
   * @summary 更新订单
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/{id}_PUT": (id: string, vo: OrderUpdateVo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/order/${id}`,
      method: "PUT",
      data: vo,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name POST /admin/v1/order/{id}/completeOrder
   * @summary 新增完工单
   * @tags 正式实验单
   * @response `200` `JsonResultObject` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/order/{id}/completeOrder_POST": (
    id: string,
    completeOrderDto: CompleteOrderDto,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/v1/order/${id}/completeOrder`,
      method: "POST",
      data: completeOrderDto,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/v1/setting
   * @summary 设置项 列表
   * @tags 系统设置
   * @response `200` `JsonResultListSettingDTO` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/setting_GET": (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/setting`,
      method: "GET",
      params,
      ...options
    }) as unknown as Promise<JsonResultListSettingDTO>,
  /**
   * No description
   * @name PUT /admin/v1/setting/save
   * @summary 保存设置项
   * @tags 系统设置
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/setting/save_PUT": (dto: SettingSaveDTO, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/setting/save`,
      method: "PUT",
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/v1/setting/{key}
   * @summary 设置项
   * @tags 系统设置
   * @response `200` `JsonResultSettingDTO` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/setting/{key}_GET": (key: string, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/setting/${key}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultSettingDTO>,
  /**
   * No description
   * @name GET /admin/v1/taskNodeConvert/list
   * @summary 列表
   * @tags 节点状态转换
   * @response `200` `JsonResultListTaskNodeConvertDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/taskNodeConvert/list_GET": (query: AdminV1TaskNodeConvertListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/taskNodeConvert/list`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListTaskNodeConvertDto>,
  /**
   * No description
   * @name GET /admin/v1/taskOrder/detail/{id}
   * @summary 详情
   * @tags 任务单
   * @response `200` `JsonResultTaskOrderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/taskOrder/detail/{id}_GET": (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/taskOrder/detail/${id}`,
      method: "GET",
      ...options
    }) as unknown as Promise<JsonResultTaskOrderDto>,
  /**
   * No description
   * @name GET /admin/v1/taskOrder/page
   * @summary 分页列表
   * @tags 任务单
   * @response `200` `JsonResultPageInfoTaskOrderDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/taskOrder/page_GET": (query: AdminV1TaskOrderPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/taskOrder/page`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoTaskOrderDto>,
  /**
   * No description
   * @name GET /admin/v1/weixinuser
   * @summary 分页条件查询用户列表
   * @tags 用户管理
   * @response `200` `JsonResultPageInfoSysUser` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/weixinuser_GET": (query: AdminV1WeixinuserGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/weixinuser`,
      method: "GET",
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysUser>,
  /**
   * No description
   * @name PUT /admin/v1/weixinuser/{id}
   * @summary 更新
   * @tags 用户管理
   * @response `200` `JsonResultObject` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  "/admin/v1/weixinuser/{id}_PUT": (id: number, param: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/v1/weixinuser/${id}`,
      method: "PUT",
      data: param,
      ...options
    }) as unknown as Promise<JsonResultObject>
};
